
.ProjectOverlay
{
    position: fixed;
    background:linear-gradient(200deg,rgba(0, 0, 51, 0.878), #321) no-repeat center;
    height:100vh;
    width:100vw;
    overflow: auto;
}

.project
{
    width:50%;
    margin: 0 auto;
    padding-top:5%;
}

#project-heading
{
    text-align: center;
    margin:3% 10%;
    color:#eee;
}

.billboard-container
{
    height:340px;
    width:85%;
    margin:auto;
    box-shadow: 4px 4px 4px #111;
}

.billboard-container img
{
    border-radius: 4px;
}

#closeProject
{
    position:fixed;
    top:25px;
    right:25px;
    z-index: 10;
    border-radius: 100%;
    font-size:52px;
    color:#eee;
}

#closeProject:hover
{
    cursor: pointer;
}

.text-container
{
    height:100%;
    color:#eee;
    margin:5% 10%;
}

.text-container p
{
    font-size:18px;
}

.ProjectOverlay li
{
    list-style-type: circle;
    display:inline-flex;
    border:3px solid #432;
    padding:10px;
    margin:10px;
}

.ProjectOverlay hr
{
    width:50%;
    margin:0;
    border:1px solid #654;
}

@media screen and (max-width:930px)
{
    .ProjectOverlay .project
    {
        width:75%;
    }
    ul
    {
        padding:0;
    }
}

@media screen and (max-width:575px)
{
  .billboard-container
  {
    height:200px;
  }

  #project-heading
  {
    font-size:30px;
    padding:10px;
  }
}
