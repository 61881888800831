.Portfolio {
  width: 100vw;
  height: 100vh;
  display: grid;
  font-family: "Open Sans", sans-serif;
  background: linear-gradient(200deg, #003, #321) no-repeat center;
  overflow: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.1rem;
  grid-auto-rows: repeat(3, 1fr);
  grid-auto-rows: minmax(200px, auto);
  min-height: calc(100vh - 1rem);
}

.blocks {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(30, 30, 30, 0);
  height: 100%;
  width: 100%;
}

.blocks img {
  transition: all 1000ms ease;
}

.blocks:hover img {
  transform: scale(1.3);
}

.blocks:hover .overlay {
  cursor: pointer;
  background: rgba(10, 10, 10, 0.8) !important;
  opacity: 1;
}

img {
  height: 100%;
  width: 100%;
}

#closePortfolio {
  position: absolute;
  top: 30px;
  font-weight: bold;
  left: 60px;
  z-index: 10;
  border-radius: 100%;
  font-size: 36px;
  color: #ddd;
  transition: all 500ms ease;
  display: inline-flex;
  align-items: center;
}

#closePortfolio span {
  font-size: 20px;
}

#closePortfolio:hover {
  cursor: pointer;
  color: orange;
}

.overlay span {
  font-weight: bold;
}

@media screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: inherit;
    min-height: inherit;
  }

  .blocks:first-child,
  .blocks:last-child,
  .blocks:nth-child(5),
  .blocks:nth-child(6) {
    grid-column: inherit;
    grid-row: inherit;
  }

  .blocks,
  .blocks img {
    max-height: 100%;
  }
}

@media screen and (max-width: 840px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .blocks img {
    min-height: 200px;
    max-height: 200px;
  }

  .blocks:first-child {
    grid-column: inherit;
    grid-row: inherit;
  }

  .blocks:nth-child(6) {
    grid-column: 1;
  }

  .blocks:nth-child(5) {
    grid-row: 3;
    grid-column: 2;
  }

  .blocks:last-child {
    grid-column: inherit;
    grid-row: inherit;
  }
  .blocks,
  .blocks img {
    max-height: 100%;
  }
}
