.About
{
    position: relative;
    color:#eee;
    font-family: "Open Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    width:100vw;
    overflow-y: scroll;
}

.About .text-container 
{    
    height:60%;
    width:35%;
    margin:auto;
}

.highlight 
{
    font-style: italic;
    color:#d4710f;
    font-weight:bold;
}

.underline
{
    text-decoration: underline;
}

.text-container h3 
{
    font-size:48px;
}

.text-container p 
{
    line-height: 1.75em;
    color:#ddd;
    padding-bottom:40px;
}

#back-home a 
{
    font-style: italic;
    color:#d4710f;
}

a 
{
    text-decoration: none;
}


@media screen and (max-width:930px)
{
  .About .text-container
  {
    width:75%;
  }

  .text-container h3 
  {
      font-size:38px;
  }
}